@import "src/themes/daikiri/styles/index.scss";
.FilesUpload {
  &__drop-zone {
    &__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: space(4) 0px;
      border: 2px dashed getvar($colors, "neutral", "300");
      border-radius: 4px;

      button {
        margin-top: 0 !important;
      }

      p {
        margin-top: space(0.25);
      }

      label {
        @extend .u-borderRadius-small;
        background-color: getvar($colors, "primary", "500");
        border: 1px solid transparent;
        color: getvar($colors, "neutral", "50");
        display: inline-block;
        padding: space(0.75) space();
        min-width: space(11.75);
        text-align: center;
        transition: all ease-in-out 0.2s;
        cursor: pointer;

        &:hover {
          background-color: getvar($colors, "primary", "700");
          color: getvar($colors, "neutral", "50");
        }
      }

      label.button--rounded {
        @extend .u-rounded-button;
      }
    }

    &__button {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    .drag-active {
      background-color: getvar($colors, "neutral", "100");
    }
  }
  &__files {
    padding: space(1) 0px;

    &__item {
      border: 1px solid getvar($colors, "neutral", "300");
      border-radius: 4px;
      padding: space(0.5) space(1);
      margin: space(0.5) 0px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      position: relative;

      &--loading {
        .loader {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          margin-right: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          .spinner {
            animation: rotate 2s linear infinite;
            width: 20px;
            height: 20px;

            & .path {
              stroke: #93bfec;
              stroke-linecap: round;
              animation: dash 1.5s ease-in-out infinite;
            }
          }
        }

        @keyframes rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
          }

          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
          }

          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }
      }

      &__player {
        width: 90px;
        height: 64px;

        iframe {
          min-width: 100% !important;
        }
      }

      button {
        margin: auto 0 !important;
      }

      &__image {
        width: space(4);
        height: space(4);
      }

      &__content {
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: space(1);
        padding-right: space(1);
        word-break: break-all;

        @include sm {
          display: flex;
        }

        p:first-child {
          margin-bottom: space(0.5);
        }

        p:last-child {
          color: getvar($colors, "neutral", "500");
        }
      }
    }

    &__svg {
      div {
        display: flex;
      }
      svg {
        width: space(4);
        height: space(4);
      }
    }
  }

  &__error {
    display: flex;
    align-items: center;
    color: getvar($colors, "error", "600");
    margin-top: space(0.25);

    svg {
      margin-right: space(0.25);
    }
  }
}
